import {MantineThemeColors} from 'ui/styles'
import {IconName} from 'ui/core'

const getStudioURL = (document: string) =>
  `https://resellam.sanity.studio/${process.env.NEXT_PUBLIC_SANITY_DATASET}/desk/${document}`

export const getItems = (colors?: MantineThemeColors) => [
  {
    key: 'product',
    title: 'Products',
    href: getStudioURL('product'),
    iconName: 'brandProducthunt' as IconName,
    color: colors?.teal[5],
  },
  {
    key: 'brand',
    title: 'Brands',
    href: getStudioURL('brand'),
    iconName: 'buildingSkyscraper' as IconName,
    color: colors?.lime[5],
  },
  {
    key: 'category',
    title: 'Categories',
    href: getStudioURL('category'),
    iconName: 'category' as IconName,
    color: colors?.indigo[5],
  },
  {
    key: 'user',
    title: 'Users',
    href: '/users',
    iconName: 'users' as IconName,
    color: colors?.cyan[5],
  },
]
