import {MantineColor} from 'ui/styles'
import {Card, Text, Group, Stack, IconName, Icon} from 'ui/core'
import Link from 'next/link'
import {ReactNode} from 'react'

interface DashboardItemProps {
  title: ReactNode
  count?: ReactNode
  href: string
  color?: MantineColor
  iconName: IconName
}

const DashboardItem = ({title, count, href, iconName, color}: DashboardItemProps) => (
  <Card data-testid="card" py={48} component={Link} href={href} sx={{backgroundColor: color}}>
    <Group position="center">
      <Icon testID="icon" name={iconName} color="white" size={48} />
      <Stack spacing="xs">
        {count && (
          <Text data-testid="count" inline color="white" size="xl" weight={700}>
            {count}
          </Text>
        )}
        <Text data-testid="title" inline color="white" size="xl">
          {title}
        </Text>
      </Stack>
    </Group>
  </Card>
)

export default DashboardItem
